import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="A" src="/static/images/avatar/2.jpg" />,
    name: 'by.archiveexperiments',
    occupation: "OG's",
    testimonial:
      "Very helpful bot and great community, humble developer so we can learn together as a community, growing up with Project Saturn since BETA until now, i know Project Saturn gonna be big as hell one day !, Keep your spirit up sir.",
  },
  {
    avatar: <Avatar alt="A" src="/static/images/avatar/2.jpg" />,
    name: 'Aldie01#1875',
    occupation: "OG's",
    testimonial:
      "Dev is good and responsive, monitors' are one of the best and bot works really well and also they keep improving every day.",
  },
  {
    avatar: <Avatar alt="U" src="/static/images/avatar/3.jpg" />,
    name: 'urwildestdream#4824',
    occupation: "OG's",
    testimonial:
      'Looking foward to growing up with saturn community. Win my membership today from an raffle. Cant wait to cop my fav sneaker’s.',
  },
  {
    avatar: <Avatar alt="M" src="/static/images/avatar/4.jpg" />,
    name: 'manongrizzly',
    occupation: "OG's",
    testimonial:
      "nice bot for adidas drop (yeezy) so my 1st run with saturnAIO very special because for 1st time finally i cop yeezy slide & foam totaly 30ea.. thanks saturn.",
  },
  {
    avatar: <Avatar alt="C" src="/static/images/avatar/5.jpg" />,
    name: 'coolboy2841',
    occupation: "OG's",
    testimonial:
      "cop sneakers in many sites with saturn. love the tutorial and helpful admin.",
  },
  {
    avatar: <Avatar alt="T" src="/static/images/avatar/6.jpg" />,
    name: 'technoble',
    occupation: "OG's",
    testimonial:
      "Fast, powerful, and easy to use All in One bot.",
  },
];

const whiteLogos = [
  'https://i.ibb.co/g3Qsmk7/Silver-Logo.png',
];

const darkLogos = [
  'https://i.ibb.co/g3Qsmk7/Silver-Logo.png',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  // const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          See what our customers love about our products. Discover how we excel in
          efficiency, durability, and satisfaction. Join us for quality, innovation,
          and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                {/* <img
                  src={logos[index]}
                  alt={`Logo`}
                  style={logoStyle}
                /> */}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
