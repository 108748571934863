import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Hero() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 35 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '80%' } }}>
          <Typography
            component="h1"
            variant={isMobile ? 'h3' : 'h1'} // Change variant based on screen size
            sx={{
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            The most reliable space to&nbsp;
            <Typography
              component="span"
              variant={isMobile ? 'h3' : 'h1'} // Change variant based on screen size
              sx={{
                color: 'cyan', // Change to cyan
                textAlign: 'center',
              }}
            >
              unleash your reselling business
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            Setup, automate, stay a head of the game. Gain access to lightning-speed monitors, insider tips, and powerful automation. <br />
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Enter your email address',
              }}
            />
            <Button variant="contained" color="primary">
              Subscribe
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Subscribe newsletter for updates of our top-tier features and services.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
