import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

function getButtonLink(title) {
  // Define the links for each pricing tier
  const links = {
    'Waiting Room Access': 'https://discord.gg/p2aMqj7usM',
    'All In One Access': 'https://invoice.xendit.co/od/saturnaio_renewal',
    // 'All In One Access': 'https://whop.com/checkout/6eIFyCv2at1MmQ4yrf-ylsx-e5Es-nX4A-LXXPxgKf6Znk/',
    'Monitor Access': 'https://invoice.xendit.co/od/saturn_monitor_access',
    // Add more tiers as needed
  };

  // Return the link for the corresponding title
  return links[title] || '/';
}

const tiers = [
  {
    title: 'Waiting Room Access',
    price: '0',
    description: [
      'Join waiting room',
      'Help center access',
      'key restocks info'
    ],
    buttonText: 'Enter Waiting Room',
    buttonVariant: 'outlined',
  },
  {
    title: 'All In One Access',
    subheader: 'Recommended',
    price: '380K',
    description: [
      'OG Lounge',
      'Product monitor access',
      'AIO Tools Access',
      'Priority help support',
      'Drop info & guides',
      'Best deals',
    ],
    buttonText: 'Join Community',
    buttonVariant: 'contained',
  },
  {
    title: 'Monitor Access',
    price: '150K',
    description: [
      'Community Lounge',
      'Products monitor access',
      'Help center access',
    ],
    buttonText: 'Join Monitor Access',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Community Access Plan
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Choose from our range of flexible pricing plans to suit your needs. Enjoy affordable options that provide access to the full spectrum of features, ensuring value for every budget. Elevate your sneaker game with our Services without breaking the bank. <br />
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'All In One Access' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'All In One Access' ? 'primary.main' : undefined,
                background:
                  tier.title === 'All In One Access'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                      tier.title === 'All In One Access' ? 'primary.contrastText' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'All In One Access' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      tier.title === 'All In One Access'
                        ? 'primary.contrastText'
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    IDR{tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'All In One Access'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'All In One Access'
                            ? 'primary.contrastText'
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href={getButtonLink(tier.title)}  // Use the function to get the link
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
